// Generated by Framer (3540919)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, Stack, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";

const cycleOrder = ["WQLkyLRf1", "NazEI_UZI"];

const breakpoints = {"WQLkyLRf1": "(min-width: 1200px)", "NazEI_UZI": "(max-width: 1199px)"}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"WQLkyLRf1": "framer-v-72rtr7", "NazEI_UZI": "framer-v-1afvkgb"};

if (isBrowser) {
                    removeHiddenBreakpointLayers("WQLkyLRf1", breakpoints, variantClassNames);
            }

const humanReadableVariantMap = {"Desktop": "WQLkyLRf1", "Phone": "NazEI_UZI"};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "WQLkyLRf1", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({"NazEI_UZI": {"WQLkyLRf1": {"transformTemplate": undefined}, "FBQLhptYF": {"transformTemplate": undefined, "background": {"src": new URL("assets/512/xrDbblP9jf1tjXF6bpDRTr8kU.gif", import.meta.url).href, "srcSet": `${new URL("assets/512/xrDbblP9jf1tjXF6bpDRTr8kU.gif", import.meta.url).href} 347w, ${new URL("assets/xrDbblP9jf1tjXF6bpDRTr8kU.gif", import.meta.url).href} 400w`, "sizes": "302px", "pixelWidth": 400, "pixelHeight": 589, "intrinsicWidth": 200, "intrinsicHeight": 294.5, "fit": "fill"}}}}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "WQLkyLRf1", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated className={cx("framer-wDBFv")} style={{"display": "contents", "pointerEvents": pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-72rtr7", className)} style={{...style}} name="Desktop"  ref={ref} {...addVariantProps("WQLkyLRf1")}><Stack className="framer-1tofkx"  transformTemplate={(_, t) => `translate(-50%, -50%) ${t}`}  background={null} direction="vertical" distribution="start" alignment="center" gap={30} __fromCanvasComponent={true} __contentWrapperStyle={{"width": "auto", "height": "auto", "padding": "0px 0px 0px 0px"}} {...addVariantProps("mMWbdoTVm")}><SSRVariants id="FBQLhptYF"><Image className="framer-8bnuc0"  name="ezgif_2_9d4f814f41"  background={{"src": new URL("assets/512/xrDbblP9jf1tjXF6bpDRTr8kU.gif", import.meta.url).href, "srcSet": `${new URL("assets/512/xrDbblP9jf1tjXF6bpDRTr8kU.gif", import.meta.url).href} 347w, ${new URL("assets/xrDbblP9jf1tjXF6bpDRTr8kU.gif", import.meta.url).href} 400w`, "sizes": "200px", "pixelWidth": 400, "pixelHeight": 589, "intrinsicWidth": 200, "intrinsicHeight": 294.5, "fit": "fill"}} data-framer-name="ezgif_2_9d4f814f41" alt="" {...addVariantProps("FBQLhptYF")}/></SSRVariants></Stack></motion.div>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-wDBFv [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wDBFv .framer-72rtr7 { position: relative; overflow: hidden; width: 1200px; height: 1080px; background-color: #000000; }", ".framer-wDBFv .framer-1tofkx { position: absolute; overflow: visible; width: min-content; height: min-content; left: 50%; top: 50%; flex: none; transform: translate(-50%, -50%); }", ".framer-wDBFv .framer-8bnuc0 { position: relative; overflow: visible; width: 200px; height: 280px; flex: none; border-bottom-left-radius: 999px; border-bottom-right-radius: 999px; border-top-right-radius: 999px; border-top-left-radius: 999px; }", "@media (min-width: 1200px) { .framer-wDBFv .hidden-72rtr7 { display: none !important; } }", "@media (max-width: 1199px) { .framer-wDBFv .hidden-1afvkgb { display: none !important; } .framer-wDBFv .framer-72rtr7 { width: 390px; height: 1080px; } .framer-wDBFv .framer-8bnuc0 { width: 302px; height: 423px; right: auto; bottom: auto; left: auto; top: auto; flex: none; aspect-ratio: unset; }}"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1080
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}, "NazEI_UZI": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const FrameraugiA20Il: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FrameraugiA20Il;

FrameraugiA20Il.displayName = "Home";

FrameraugiA20Il.defaultProps = {"width": 1200, "height": 1080};

addFonts(FrameraugiA20Il, []);